import React from "react"
import styled from "styled-components"
// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import ServicesDescriptions from "../constants/services_descriptions"
import Service from "../components/service"
import ClientsCarousel from "../components/clients"
import BigTitle from "../components/titles"
import LottieAnimation from "../components/lottie_animation"
import animation from "../assets/animations/background.json"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="OGD42 - Home - Experts in building Software for Real Estate" />
      <BackgroundContainer>
        <LottieAnimation
          options={{
            animationData: animation,
          }}
        />
      </BackgroundContainer>
      <div
        className="container-fluid"
        style={{
          position: "relative",
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div
          className="row justify-content-center"
          style={{
            minHeight: "100vh",
            backgroundColor: "rgba(252, 81, 133, 0.5)",
          }}
        >
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex ">
            <Banner />
          </div>
        </div>
        <div
          className="row justify-content-center home-section"
          style={{
            backgroundColor: "rgba(252, 81, 133, 0.7)",
          }}
        >
          <div
            className="col-sm-11 col-md-11 col-lg-6 col-xl-6 d-flex"
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BigTitle
              style={{
                textAlign: "center",
                fontSize: "2.2rem",
              }}
              data-aos="fade-up"
              data-aos-delay={400}
            >
              We built products for:
            </BigTitle>
            <ClientsCarousel />
          </div>
        </div>
        <div
          className="row justify-content-center home-section"
          style={{
            backgroundColor: "rgba(252, 81, 133, 0.9)",
          }}
        >
          <div className="col-sm-11 col-md-11 col-lg-6 col-xl-6 d-flex">
            <ServicesContainer>
              <BigTitle
                style={{ textAlign: "center", fontSize: "2.8rem" }}
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-anchor-placement="bottom-center"
              >
                Our Services:
              </BigTitle>
              {ServicesDescriptions.map((service, index) => (
                <Service key={`si${index}`} {...service} index={index} />
              ))}
            </ServicesContainer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const BackgroundContainer = styled.div`
  z-index: -100;
  position: fixed;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  top: 0;
  @media (max-width: 481px) {
    width: 200%;
  }
`

const ServicesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 481px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export default IndexPage
