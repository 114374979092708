import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { SecondaryTheme } from "../constants/theme"

const Service = ({
  title,
  description,
  color = "4c5c96",
  picture = "",
  index = 1,
}) => (
  <Container
    color={color}
    picture={picture}
    data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
    data-aos-delay={200}
    data-aos-anchor-placement="bottom-bottom"
  >
    <div
      className="mainSection"
      data-aos="fade-up"
      data-aos-delay={400}
      data-aos-anchor-placement="bottom-bottom"
    >
      <h1>{title}</h1>
      <span>{description}</span>
    </div>
    {/* <Link to='/services'>More Information</Link> */}
  </Container>
)

const Container = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: row;
  }
  .mainSection {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: 90%;
      margin-bottom: 20px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  h1 {
    color: ${SecondaryTheme.accent};
    font-size: 2.2rem;
    margin-bottom: 0.8em;
    padding-bottom: 0.4em;
    letter-spacing: 0.2rem;
    width: 100%;
    transition: border-width 0.3s ease-in-out;
    border-bottom: solid 2px ${SecondaryTheme.primary};
    &:hover {
      border-bottom: solid 6px ${SecondaryTheme.primary};
    }
    @media (max-width: 768px) {
      font-size: 2rem;
      text-align: center;
      width: 90%;
    }
  }

  span {
    color: gray;
    width: 50%;
    font-size: 1.5rem;
    width: 100%;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }

  a {
    margin-top: 20px;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    padding: 10px;
    color: ${SecondaryTheme.accent};
    padding-bottom: 15px;
    text-decoration: none;
    background: linear-gradient(
      to bottom,
      ${SecondaryTheme.primary} 0%,
      ${SecondaryTheme.primary} 100%
    );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 4px;
    text-decoration: none;
    transition: all 0.2s;
    &:hover {
      background-size: 4px 60px;
      color: ${SecondaryTheme.secondary};
    }
  }
`

Service.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

export default Service
