import React from "react"
import styled from "styled-components"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "react-awesome-slider/dist/styles.css"
import * as AwesomeSliderStyles from "react-awesome-slider/dist/"
import * as CubeAnimation from "react-awesome-slider/dist/custom-animations/cube-animation.css"
import { PrimaryTheme } from "../constants/theme"
// Logos
import VaynerLogo from "../images/clients/vayner-logo.png"
import FitBody from "../images/clients/fitbody-logo.png"
import SMLogo from "../images/clients/sm-logo.png"
import StellaLogo from "../images/clients/stella-logo.png"
import IrtLogo from "../images/clients/irt-logo.png"
import CAndWLogo from "../images/clients/c_and_w.png"
import CarsLogo from "../images/clients/cars.png"

const AutoplaySlider = withAutoplay(AwesomeSlider)

const ClientsCarousel = () => {
  return (
    <StyledContainer>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false}
        animation="cubeAnimation"
        interval={2000}
        cssModule={[AwesomeSliderStyles, CubeAnimation]}
        organicArrows={false}
      >
        <div data-src={IrtLogo} alt="Irt Living logo">
          <p>Real Estate</p>
        </div>
        <div data-src={CAndWLogo} alt="Cushman And Wakefield logo">
          <p>Real Estate</p>
        </div>
        <div data-src={CarsLogo} alt="CARS.COM logo">
          <p>Biggest Car sales website in the USA</p>
        </div>
        <div data-src={VaynerLogo} alt="Vayner Media logo">
          <p>Biggest Marketing Firm in the USA</p>
        </div>
        <div data-src={FitBody} alt="FitBody Logo">
          <p>Fitness/Healthcare</p>
        </div>
        <div data-src={StellaLogo} alt="Stella Artois Logo">
          <p>European Beer</p>
        </div>
        <div data-src={SMLogo} alt="Smart Clinic Logo">
          <p>Healthcare Technology</p>
        </div>
      </AutoplaySlider>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${PrimaryTheme.secondaryBackground};
  padding-left: 10px;
  padding-right: 10px;

  & .awssld__content {
    background-color: transparent !important;
  }

  & .awssld__bullets > button {
    background: ${PrimaryTheme.text};
  }

  .awssld__content > img {
    object-fit: contain;
  }
  .awssld__content > div {
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .awssld__content > div > p {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${PrimaryTheme.text};
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  }
`

export default ClientsCarousel
