import React from "react"
import styled from "styled-components"
import BigTitle from "./titles"
import { SecondaryTheme } from "../constants/theme"

function loadCalendly() {
  window.Calendly.initPopupWidget({ url: "https://calendly.com/ogd42/30min" })
  return false
}
const Banner = () => (
  <StyledContainer>
    <div className="inner" data-aos="fade-up" data-aos-delay={300}>
      <BigTitle style={{ textAlign: "center" }}>
        We bring modern technology to businesses
      </BigTitle>
      <h4 style={{ color: "white", textAlign: "center", marginBottom: 20 }}>
      Unlocking Possibilities with Cutting-Edge Software Solutions
      </h4>
      <button href="#" onClick={loadCalendly}>
        Schedule a call with us!
      </button>
    </div>
  </StyledContainer>
)

const StyledContainer = styled.div`
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;

    & h1 {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .callToAction {
    width: 50%;
  }

  span {
    color: white;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
  }

  button {
    border-color: transparent;
    text-decoration: none;
    color: ${SecondaryTheme.secondary};
    background-color: ${SecondaryTheme.accent};
    padding: 15px;
    border-radius: 10px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-align: center;
    width: 80%;
    font-size: 1.4rem;
    max-width: 500px;
    cursor: pointer;

    @media (max-width: 1025px) {
      font-size: 1.4rem;
      width: 80%;
    }
    @media (max-width: 768px) {
      font-size: 1.2rem;
      width: 90%;
    }
  }
`

export default Banner
