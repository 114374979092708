// Pictures
import MobileDevelopment from '../images/mobile-development.jpeg';
import WebDevelopment from '../images/web-development.jpeg';

const Services = [
  {
    title       : 'Mobile\nDevelopment',
    description : 'Excellent mobile apps for startup ideas, with the latest tools and technologies.',
    picture     : MobileDevelopment,
  },
  {
    title       : 'Web Development',
    description :
      'We have built hundreds of fantastic web apps, from Healthcare to Marketing websites. We got your back!',
    picture     : WebDevelopment,
  },
];

export default Services;
